<template>
  <div class="flex align-center flex-col justify-center my-4 w-full">
    <div class="container my-6 flex justify-end">
      <div v-if="message" class="bg-gray-300 px-4 py-2 mr-4 rounded">
        {{ message._message }}
      </div>

      <AppButton
        :loading="loading"
        @clicked="save()"
        class="mr-2"
        color="bg-green-500 hover:bg-green-700 text-white"
      >
        Salvar classe de signo
      </AppButton>
    </div>

    <div class="flex container items-start">
      <div
        class="mr-6 w-1/2"
        v-if="languages.length > 0 && langs && Object.keys(langs).length > 0"
      >
        <nav class="flex mb-6">
          <div
            v-for="language in languages"
            :key="`${language.abbreviation}-nav`"
            @click.prevent="active = language.abbreviation"
            class="px-4 py-2 rounded mr-4 text-gray-600 border border-gray-300 cursor-pointer"
            :class="{
              'bg-gray-100 border-gray-700 text-gray-700':
                active == language.abbreviation,
              'bg-gray-300': active != language.abbreviation
            }"
          >
            {{ language.title }}
          </div>
        </nav>

        <div
          v-for="language in languages"
          :key="language.abbreviation"
          :class="{ hidden: active != language.abbreviation }"
        >
          <label class="block mb-6 bg-white rounded shadow p-4 ">
            <span class="label"
              >Título da classe de signo [{{ language.title }}]</span
            >
            <input
              type="text"
              class="input"
              v-model="langs[language.abbreviation].title"
            />
          </label>
          <label class="block mb-6 bg-white rounded shadow p-4 ">
            <span class="label">Descrição</span>
            <TextEditor
              v-model="langs[language.abbreviation].description"
            ></TextEditor>
          </label>
          <label class="block mb-6 bg-white rounded shadow p-4 ">
            <span class="label">Exemplo</span>
            <TextEditor
              v-model="langs[language.abbreviation].example"
            ></TextEditor>
          </label>
        </div>
      </div>

      <div v-else class="mr-6 w-1/2">
        <nav class="flex mb-6">
          <div
            class="bg-gray-300 px-4 py-2 rounded mr-4 text-gray-600 border border-gray-300 cursor-pointer"
          >
            Carregando...
          </div>
        </nav>
        <div class="bg-white rounded shadow p-4 mb-6"></div>
      </div>

      <div class="w-1/2 min-w-1/2 bg-white rounded shadow p-4">
        <div class="w-full flex">
          <label class="w-32 block mb-6 pr-2">
            <span class="label">Nº Classe</span>
            <input type="number" class="input" v-model.number="sign.number" />
          </label>

          <label class="w-full ml-3 block mb-6">
            <span class="label">Título gerenciador</span>
            <input type="text" class="input" v-model="sign.titleAdmin" />
            <p class="italic text-gray-500 text-sm ml-1 mt-1">
              Só aparece pra você.
            </p>
          </label>
        </div>

        <div class="w-full flex">
          <label class="w-1/2 block mb-6">
            <span class="label">Período</span>
            <select class="select" v-model="sign.period">
              <option value="grounding">Fundamentação</option>
              <option value="presentation">Presentação</option>
              <option value="representing">Representação</option>
              <option value="communicating">Comunicação</option>
            </select>
          </label>
          <label class="w-1/2 block mb-6 ml-3">
            <span class="label">Fase</span>
            <select class="select" v-model="sign.phasing">
              <option value="perceiving">Percebendo</option>
              <option value="experiencing">Experimentando</option>
              <option value="understanding">Compreendendo</option>
              <option value="sharing">Compartilhando</option>
            </select>
          </label>
        </div>

        <div class="w-full flex">
          <label class="w-1/3 block mb-6">
            <span class="label">1C Correlato</span>
            <select class="select" v-model="sign.c3">
              <option v-for="(value, name) in c3" :key="name" :value="name">{{
                value.pt.title
              }}</option>
            </select>
          </label>

          <label class="w-1/3 block mb-6 ml-3">
            <span class="label">2C Correlato</span>
            <select class="select" v-model="sign.c2">
              <option v-for="(value, name) in c2" :key="name" :value="name">{{
                value.pt.title
              }}</option>
            </select>
          </label>

          <label class="w-1/3 block mb-6 ml-3">
            <span class="label">3C Correlato</span>
            <select class="select" v-model="sign.c1">
              <option v-for="(value, name) in c1" :key="name" :value="name">{{
                value.pt.title
              }}</option>
            </select>
          </label>
        </div>

        <div class="w-full flex">
          <div class="w-1/3 flex">
            <label class="block mb-6">
              <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
                >1C</span
              >
              <input type="text" class="input" v-model="sign.thricotomy.s" />
            </label>
            <label class="block mb-6 ml-1">
              <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
                >2C</span
              >
              <input type="text" class="input" v-model="sign.thricotomy.o" />
            </label>
            <label class="block mb-6 ml-1">
              <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
                >3C</span
              >
              <input type="text" class="input" v-model="sign.thricotomy.i" />
            </label>
          </div>

          <div class="w-1/3 ml-3">
            <label class="block">
              <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
                >Cor da célula na tabela</span
              >
            </label>
            <Compact
              @input="updateColor"
              :value="sign.color"
              :palette="palette"
              class="w-1/2 mr-2 mb-4"
            />
          </div>
        </div>

        <div class="w-full flex">
          <label class="block mb-6">
            <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
              >Oi</span
            >
            <input type="text" class="input" v-model="sign.eleven.io" />
          </label>
          <label class="block mb-6 ml-1">
            <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
              >Ii</span
            >
            <input type="text" class="input" v-model="sign.eleven.ii" />
          </label>
          <label class="block mb-6 ml-1">
            <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
              >S</span
            >
            <input type="text" class="input" v-model="sign.eleven.s" />
          </label>
          <label class="block mb-6 ml-1">
            <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
              >Od</span
            >
            <input type="text" class="input" v-model="sign.eleven.do" />
          </label>
          <label class="block mb-6 ml-1">
            <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
              >Id</span
            >
            <input type="text" class="input" v-model="sign.eleven.di" />
          </label>
          <label class="block mb-6 ml-1">
            <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
              >If</span
            >
            <input type="text" class="input" v-model="sign.eleven.fi" />
          </label>
          <label class="block mb-6 ml-1">
            <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
              >S-Od</span
            >
            <input type="text" class="input" v-model="sign.eleven.sdo" />
          </label>
          <label class="block mb-6 ml-1">
            <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
              >S-Id</span
            >
            <input type="text" class="input" v-model="sign.eleven.sdi" />
          </label>
          <label class="block mb-6 ml-1">
            <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
              >S-If</span
            >
            <input type="text" class="input" v-model="sign.eleven.sfi" />
          </label>
          <label class="block mb-6 ml-1">
            <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
              >S-Od-Id</span
            >
            <input type="text" class="input" v-model="sign.eleven.sdodi" />
          </label>
          <label class="block mb-6 ml-1">
            <span class="whitespace-no-wrap text-xs font-bold text-gray-700"
              >S-Od-If</span
            >
            <input type="text" class="input" v-model="sign.eleven.sdofi" />
          </label>
        </div>

        <ElevenTrichotomies
          v-if="!loading"
          :sign="sign"
          @setC1="setC1"
          @setC2="setC2"
          @setC3="setC3"
        />

        <div class="w-full">
          <SolenoidComponent v-if="!loading" :sign="sign" class="w-1/2" />

          <button class="button mt-6" @click.prevent="downloadImage()">
            Download PNG
          </button>

          <canvas
            id="canvas"
            width="800"
            height="800"
            class="hidden fixed bottom-0 right-0"
          ></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppButton from "@/components/AppButton";
import SolenoidComponent from "@/components/SolenoidComponent";
import ElevenTrichotomies from "@/components/ElevenTrichotomies";
import TextEditor from "@/components/TextEditor/TextEditor";
import { Compact } from "vue-color";
import Canvg from "canvg";

export default {
  name: "PagesForm",
  components: {
    AppButton,
    SolenoidComponent,
    ElevenTrichotomies,
    Compact,
    TextEditor
  },
  filters: {},
  props: {},
  data: function() {
    return {
      loading: true,
      active: null,
      message: null,

      palette: [
        "#ec7d7f",
        "#db4447",
        "#f9ad82",
        "#ab74af",
        "#fdfa73",
        "#ccc92c",
        "#8bd69b",
        "#7e8fbf",
        "#436cdd"
      ],

      sign: {
        titleAdmin: "",
        number: 1,
        thricotomy: {
          s: "1",
          o: "1'",
          i: "1"
        },
        color: "",
        eleven: {
          io: "1",
          ii: "1",
          s: "1",
          do: "1",
          di: "1",
          fi: "1",
          sdo: "1",
          sdi: "1",
          sfi: "1",
          sdodi: "1",
          sdofi: "1"
        },
        period: "",
        phasing: "",
        c1: "",
        c2: "",
        c3: "",
        visualization: {
          C1: [0, 0],
          C2: [0, 0],
          C3: [0, 0]
        },
        langs: {}
      },

      langs: null
    };
  },
  computed: {
    ...mapState({
      classes: state => state.Classes.list,
      languages: state => state.Settings.languages,
      c1: state => state.Settings.c1,
      c2: state => state.Settings.c2,
      c3: state => state.Settings.c3
    })
  },
  watch: {},
  async created() {
    await this.$store.dispatch("Settings/list");
  },
  async mounted() {
    if (this.classes.length == 0) {
      await this.$store.dispatch("Classes/list");
    }

    const param = this.$route.params.id;
    console.log(param);

    if (param == "adicionar") {
      if (this.languages.length > 0) {
        const langs = {};
        this.languages.forEach(item => {
          langs[item.abbreviation] = {
            title: "",
            description: "",
            example: ""
          };
        });

        this.active = this.languages[0].abbreviation;
        this.langs = langs;

        this.loading = false;
      }
    } else {
      const sign = await this.$store.dispatch("Classes/single", param);
      const langs = await this.$store.dispatch("Classes/langs", sign._id);

      this.active = this.languages[0].abbreviation;

      if (sign) {
        this.languages.forEach(item => {
          if (!langs[item.abbreviation]) {
            langs[item.abbreviation] = {
              title: "",
              description: "",
              example: ""
            };
          }
        });

        this.sign = sign;
        this.langs = langs;

        this.loading = false;
      }
    }
  },
  methods: {
    updateColor(event) {
      this.sign.color = event.hex;
    },

    setC1(value) {
      if (!this.sign.visualization) this.sign.visualization = {};
      this.sign.visualization.C1 = value;
    },
    setC2(value) {
      if (!this.sign.visualization) this.sign.visualization = {};
      this.sign.visualization.C2 = value;
    },
    setC3(value) {
      if (!this.sign.visualization) this.sign.visualization = {};
      this.sign.visualization.C3 = value;
    },

    async save() {
      this.loading = true;
      this.message = null;

      const param = this.$route.params.id;

      if (param == "adicionar") {
        const sign = {
          ...this.sign,
          langs: {},
          date: Date.now()
        };

        const langsArray = Object.keys(this.langs);
        for (const lang of langsArray) {
          sign.langs[lang] = this.langs[lang].title;
        }

        const langs = {
          ...this.langs
        };

        const response = await this.$store.dispatch("Classes/add", [
          sign,
          langs
        ]);
        if (response) {
          this.message = response;
        }
      } else {
        const sign = {
          ...this.sign
        };

        const langsArray = Object.keys(this.langs);
        for (const lang of langsArray) {
          sign.langs[lang] = this.langs[lang].title;
        }

        const langs = {
          ...this.langs
        };

        const response = await this.$store.dispatch("Classes/update", [
          sign,
          langs
        ]);
        if (response) {
          this.message = response;
        }
      }

      this.loading = false;
    },

    async downloadImage() {
      const svgDOM = document.getElementById("solenoidSVG");
      const serializer = new XMLSerializer();

      const svgElement = serializer.serializeToString(svgDOM);

      const canvas = document.querySelector("canvas");
      const ctx = canvas.getContext("2d");

      var v = Canvg.fromString(ctx, svgElement);
      v.start();

      setTimeout(() => {
        var img = canvas.toDataURL("image/png");
        /* Change MIME type to trick the browser to downlaod the file instead of displaying it */
        img = img.replace(
          /^data:image\/[^;]*/,
          "data:application/octet-stream"
        );

        /* In addition to <a>'s "download" attribute, you can define HTTP-style headers */
        img = img.replace(
          /^data:application\/octet-stream/,
          "data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=Canvas.png"
        );

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none;";
        a.href = img;
        a.download = `Solenoid-${this.sign.number}.png`;
        a.click();
        a.remove();
      }, 500);
    }
  }
};
</script>

<style scoped lang="scss"></style>
